(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/systems/assets/javascripts/r-systems.js') >= 0) return;  svs.modules.push('/components/sport/systems/assets/javascripts/r-systems.js');
'use strict';

const RSYSTEMS = Object.freeze({
  28: 'R7-0-36',
  29: 'R8-0-81',
  30: 'R9-0-222',
  31: 'R10-0-567',
  32: 'R11-0-729',
  33: 'R0-7-16',
  34: 'R0-11-16',
  35: 'R2-5-12',
  36: 'R3-3-24',
  37: 'R4-0-9',
  38: 'R4-4-24',
  39: 'R4-4-144',
  40: 'R4-5-48',
  41: 'R4-6-24',
  42: 'R4-6-72',
  43: 'R4-7-48',
  44: 'R4-7-144',
  45: 'R5-0-18',
  46: 'R5-3-36',
  47: 'R5-5-108',
  48: 'R6-3-24',
  49: 'R6-3-72',
  50: 'R6-4-44',
  51: 'R7-2-34',
  52: 'R7-2-108',
  53: 'R8-0-27',
  54: 'R8-2-324',
  55: 'R8-3-504',
  56: 'R9-0-243',
  57: 'R9-0-1356',
  58: 'R10-0-153'
});
if (svs.isServer) {
  module.exports = RSYSTEMS;
} else {
  setGlobal('svs.components.sport.systems.RSYSTEMS', RSYSTEMS);
}

 })(window);