(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/systems/assets/javascripts/get-system-key-from-number.js') >= 0) return;  svs.modules.push('/components/sport/systems/assets/javascripts/get-system-key-from-number.js');
'use strict';

let RSYSTEMS;
let USYSTEMS;
if (svs.isServer) {
  RSYSTEMS = require('./r-systems.es6');
  USYSTEMS = require('./u-systems.es6');
} else {
  const systems = svs.components.sport.systems;
  RSYSTEMS = systems.RSYSTEMS;
  USYSTEMS = systems.USYSTEMS;
}
const getSystemKeyFromNumber = systemNum => RSYSTEMS[systemNum] || USYSTEMS[systemNum];
if (svs.isServer) {
  module.exports = getSystemKeyFromNumber;
} else {
  setGlobal('svs.components.sport.systems.getSystemKeyFromNumber', getSystemKeyFromNumber);
}

 })(window);