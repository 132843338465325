(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/systems/assets/javascripts/get-system-num-from-system-label.js') >= 0) return;  svs.modules.push('/components/sport/systems/assets/javascripts/get-system-num-from-system-label.js');
'use strict';

let SYSTEMS;
if (svs.isServer) {
  SYSTEMS = require('./system-data.es6');
} else {
  SYSTEMS = svs.components.sport.systems.SYSTEMS;
}
const getSystemNumFromSystemLabel = system => {
  var _SYSTEMS$system;
  return (_SYSTEMS$system = SYSTEMS[system]) === null || _SYSTEMS$system === void 0 ? void 0 : _SYSTEMS$system.systemNum;
};
if (svs.isServer) {
  module.exports = getSystemNumFromSystemLabel;
} else {
  setGlobal('svs.components.sport.systems.getSystemNumFromSystemLabel', getSystemNumFromSystemLabel);
}

 })(window);