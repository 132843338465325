(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/systems/assets/javascripts/get-maltipset-r-system.js') >= 0) return;  svs.modules.push('/components/sport/systems/assets/javascripts/get-maltipset-r-system.js');

'use strict';

const MaltipsetSystems = {
  R11: {
    length: 18
  },
  R12: {
    length: 27
  },
  R13: {
    length: 60
  },
  R14: {
    length: 138
  }
};

const getMaltipsetRSystem = noSelectedMatches => {
  if (MaltipsetSystems["R".concat(noSelectedMatches)]) {
    return MaltipsetSystems["R".concat(noSelectedMatches)].length;
  }
  return undefined;
};
if (svs.isServer) {
  module.exports = getMaltipsetRSystem;
} else {
  setGlobal('svs.components.sport.systems.getMaltipsetRSystem', getMaltipsetRSystem);
}

 })(window);