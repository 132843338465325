(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/systems/assets/javascripts/get-system-from-number.js') >= 0) return;  svs.modules.push('/components/sport/systems/assets/javascripts/get-system-from-number.js');
'use strict';

let SYSTEMS;
let getSystemKeyFromNumber;
if (svs.isServer) {
  SYSTEMS = require('./system-data.es6');
  getSystemKeyFromNumber = require('./get-system-label-from-number.es6');
} else {
  SYSTEMS = svs.components.sport.systems.SYSTEMS;
  getSystemKeyFromNumber = svs.components.sport.systems.getSystemKeyFromNumber;
}
const getSystemFromNumber = systemNum => {
  const systemLabel = getSystemKeyFromNumber(systemNum);
  return SYSTEMS[systemLabel];
};
if (svs.isServer) {
  module.exports = getSystemFromNumber;
} else {
  setGlobal('svs.components.sport.systems.getSystemFromNumber', getSystemFromNumber);
}

 })(window);