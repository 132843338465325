(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/systems/assets/javascripts/u-systems.js') >= 0) return;  svs.modules.push('/components/sport/systems/assets/javascripts/u-systems.js');
'use strict';

const USYSTEMS = Object.freeze({
  59: 'U0-7-10',
  60: 'U0-10-38',
  61: 'U0-11-65',
  62: 'U3-6-20',
  63: 'U3-6-50',
  64: 'U3-7-98',
  65: 'U4-3-66',
  66: 'U4-4-16',
  67: 'U4-4-44',
  68: 'U4-5-30',
  69: 'U4-5-68',
  70: 'U4-7-200',
  71: 'U5-5-44',
  72: 'U5-5-176',
  73: 'U5-6-274',
  74: 'U6-3-36',
  75: 'U6-4-66',
  76: 'U6-4-224',
  77: 'U7-3-100',
  78: 'U7-4-464',
  79: 'U8-2-253',
  80: 'U8-3-171',
  81: 'U8-3-598',
  82: 'U9-2-202',
  83: 'U11-0-133',
  84: 'U13-0-105'
});
if (svs.isServer) {
  module.exports = USYSTEMS;
} else {
  setGlobal('svs.components.sport.systems.USYSTEMS', USYSTEMS);
}

 })(window);