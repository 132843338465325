(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/systems/assets/javascripts/get-system-label-from-number.js') >= 0) return;  svs.modules.push('/components/sport/systems/assets/javascripts/get-system-label-from-number.js');
'use strict';

let getSystemFromNumber;
if (svs.isServer) {
  getSystemFromNumber = require('./get-system-from-number.es6');
} else {
  getSystemFromNumber = svs.components.sport.systems.getSystemFromNumber;
}
const SystemTypes = {
  RSYS: 'R',
  USYS: 'U'
};
const getSystemLabelFromNumber = systemNum => {
  const system = getSystemFromNumber(systemNum);
  const systemLabel = "".concat(system.systemType, " ").concat(system.numFullHedges, "-").concat(system.numHalfHedges, "-").concat(system.rows.length);
  if (system.systemType === SystemTypes.RSYS) {
    return "".concat(systemLabel, " (").concat(system.guaranty, ")");
  }
  return systemLabel;
};
if (svs.isServer) {
  module.exports = getSystemLabelFromNumber;
} else {
  setGlobal('svs.components.sport.systems.getSystemLabelFromNumber', getSystemLabelFromNumber);
}

 })(window);